<template>
  <div class="system-reget-pass">
    <el-steps :active="active" process-status="finish" finish-status="success" simple>
      <!-- 选择找回方式 -->
      <el-step :title="$t('common.choseBackWay')"></el-step>
      <!-- 输入账号 -->
      <el-step :title="$t('common.inputCount')"></el-step>
      <!-- 完成 -->
      <el-step :title="$t('common.isOk')"></el-step>
    </el-steps>

    <div class="show-content">
      <!-- 第一步，选择找回方式 -->
      <div class="step-one" v-if="active == 0">
        <!-- 方式一：通过用户名找回 -->
        <el-link type="primary" class="choseway" @click="doChoseWay('name')">{{ $t('common.backWithUserName') }}</el-link>
        <!-- 方式二：通过邮箱找回 -->
        <el-link type="primary" class="choseway" @click="doChoseWay('mail')">{{ $t('common.backWithEmail') }}</el-link>
      </div>
      <div class="step-two" v-if="active == 1">
        <div>
          <!-- 请输入账号 -->
          <el-input type="text" v-model="accountVal" :placeholder="$t('common.inputCountPlease')"
            style="width: 350px; margin-right: 20px;" @change="accoutValChange" />
          <!-- 校验账号 -->
          <el-button type="primary" @click="doCheckAccountVal" :disabled="!accountVal">{{ $t('common.checkCount') }}
          </el-button>
        </div>
      </div>
      <!-- 请到 {{ showEmail || accountVal }} 邮箱中查收 -->
      <div class="step-three" v-if="active == 2">
        <!-- 当通过用户名查找的时候，使用的是 showEmail；
             当通过邮箱查找的时候，使用的是 输入的邮箱，即：accountVal -->
        {{ $t('common.lookForTips', { where: showEmail || accountVal }) }}
      </div>
    </div>
    <div class="model-btn">
      <!-- 上一步 -->
      <el-button v-if="active < 2" style="margin-top: 12px;" @click="pre" :disabled="active == 0">
        {{ $t('common.preStep') }}</el-button>
      <!-- 完成 -->
      <el-button v-else style="margin-top: 12px;" @click="doColseModel">{{ $t('common.isOk') }}</el-button>
    </div>

    <!-- 邮箱信息确认 -->
    <el-dialog :title="$t('common.mailInfoSure')" :visible.sync="showModel" width="700px" append-to-body>
      <!-- 您注册时邮箱是： -->
      <div style="font-size: 18px; padding: 40px">
        {{ $t('common.yourMail') }}：{{ showEmail || $t('common.null') }}
      </div>

      <div slot="footer" class="dialog-footer">
        <!-- 取消 -->
        <el-button @click="showModel = false">{{ $t('common.cancel') }}</el-button>
        <!-- 确定 -->
        <el-button type="primary" @click="doOkMailInfo">{{ $t('common.sure') }}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>

export default {
  name: "ReGetPass",



  data () {
    return {
      flag: true, // 用于防抖

      active: 0,
      way: "", // 选择的方式 name；用户名方式，mail 邮箱方式
      accountVal: "",

      showEmail: "", // 用于用户名校验时发送验证
      showModel: false,
      canReset: false, // 是否可以发送邮件校验
    };
  },
  methods: {
    pre () {
      this.active--;
    },
    next () {
      this.active++;
    },
    /** 选择找回方式 */
    doChoseWay (name) {
      this.way = name;
      this.accountVal = "";
      this.next();
    },
    /** accountVal 校验 */
    doCheckAccountVal () {
      this.accountVal = (this.accountVal || "").trim();

      const { way, accountVal } = this;
      // 账号不可为空！
      if (!accountVal) {
        this.$message.error(this.$t("common.countNotNull"));
        return;
      }

      if (way == "mail") {
        const reg = /^\w+@[a-z0-9]+\.[a-z]{2,4}$/;
        const res = reg.test(accountVal);
        if (!res) {
          this.$message.error(this.$t("common.mailFormatIsErr"));
          return;
        }
        this.canReset = true;
        this.$message.success(this.$t("common.checkIsOk"));

        this.doPostReset();
        return;
      }

      if (way == "name") {
        if (!this.flag) {
          return;
        }
        this.flag = false;
        // 发送校验接口
        let _this = this;

        this.showEmail = "";
        this.$http
          .doCheckReset({ username: accountVal })
          .then(
            ({ email }) => {
              // 校验成功，提示用户邮箱信息
              _this.showEmail = email;
              _this.showModel = true;
            },
            (err) => {
              _this.$message.error(`${err}`);
            }
          )
          .finally(() => {
            setTimeout(() => {
              _this.flag = true;
            }, 10);
          });
      }
    },

    /** 输入的账号新变化 */
    accoutValChange () {
      this.showEmail = "";
      this.canReset = false;
    },
    /** 确认邮箱信息 */
    doOkMailInfo () {
      this.canReset = true;
      this.doPostReset();
    },

    /** 调用发送邮件接口 */
    doPostReset () {
      if (!this.flag) {
        return;
      }
      this.flag = false;
      let _this = this;
      this.$http
        .doPostReset({ username: this.accountVal })
        .then(
          (data) => {
            _this.next();
            _this.showModel = false;
          },
          (err) => {
            _this.$message.error(`${err}`);
          }
        )
        .finally(() => {
          setTimeout(() => {
            _this.flag = true;
          }, 500);
        });
    },

    /** 关闭模态框 */
    doColseModel () {
      this.$emit("doColseModel");
    },
  },
};
</script>

<style lang="scss" scoped>
.system-reget-pass {
  .show-content {
    padding: 50px 100px;
    min-height: 100px;

    .step-one {
      .choseway {
        margin-right: 50px;
        font-size: 18px;
        line-height: 36px;
        margin-bottom: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .step-three {
      color: #999999;
      font-size: 18px;
    }
  }

  .model-btn {
    text-align: right;
  }
}</style>