<template>
  <div class="system-login">
    <el-card class="system-login-card">
      <!-- 欢迎登录 -->
      <!-- <div class="login-title">{{ $t("common.welcomeToLogin") }}</div> -->
      <div class="login-content">
        <el-form ref="registerForm" :model="registerForm" :rules="registerRules" class="register-form">
          <el-form-item prop="username">
            <el-input v-model="registerForm.username" type="text" auto-complete="off" placeholder="请输入邮箱账号"> </el-input>
          </el-form-item>
          <!-- <el-form-item prop="email">
            <el-input v-model="registerForm.email" type="text" auto-complete="off" placeholder="邮箱"> </el-input>
          </el-form-item> -->
          <el-form-item prop="password">
            <el-input v-model="registerForm.password" type="password" auto-complete="off" placeholder="请输入密码"
              @keyup.enter.native="register" show-password>
            </el-input>
          </el-form-item>
          <el-form-item prop="confirmPassword">
            <el-input v-model="registerForm.confirmPassword" type="password" auto-complete="off" placeholder="确认密码"
              @keyup.enter.native="register" show-password>
            </el-input>
          </el-form-item>

          <el-form-item style="width: 100%">
            <el-button :loading="loading" size="medium" type="primary" style="width: 100%"
              @click.native.prevent="register">
              <span v-if="!loading">注 册</span>
              <span v-else>注 册 中...</span>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import ReGetPass from "./reGetPass.vue";
import userApi from "@/api/user";
import { mapMutations } from "vuex";
import { setToken } from "@/utils/auth";

export default {
  name: "login",
  components: {
    ReGetPass,
  },
  data () {
    const equalToPassword = (rule, value, callback) => {
      if (this.registerForm.password !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      registerForm: {
        username: "",
        password: "",
        confirmPassword: "",
        code: "",
        uuid: "",
      },
      registerRules: {
        // username: [
        //   { required: true, trigger: "blur", message: "请输入您的账号" },
        //   { min: 2, max: 20, message: "用户账号长度必须介于 2 和 20 之间", trigger: "blur" },
        // ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" },
          { min: 5, max: 20, message: "用户密码长度必须介于 5 和 20 之间", trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, trigger: "blur", message: "请再次输入您的密码" },
          { required: true, validator: equalToPassword, trigger: "blur" },
        ],
        username: [
          // 请输入邮箱账号
          { required: true, message: "请输入正确的邮箱", trigger: "blur" },
          // 请输入正确的邮箱地址
          { type: "email", message: "请输入正确的邮箱", trigger: ["blur"] },
        ],
      },
      loading: false,
    };
  },
  methods: {
    handleRegister () {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.loading = true;

          userApi
            .register(this.registerForm)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success("恭喜您注册成功,请前往邮箱激活");
                this.$router.push("/");
              }
              // const username = this.registerForm.username;
              // this.$alert("<font color='red'>恭喜你，您的账号 " + username + " 注册成功！</font>", '系统提示', {
              //   dangerouslyUseHTMLString: true,
              //   type: 'success'
              // }).then(() => {
              // }).catch(() => {});
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    debounce (fn, delay) {
      let time = null;//time用来控制事件的触发
      return function () {
        if (time !== null) {
          clearTimeout(time);
        }
        time = setTimeout(() => {
          fn.call(this);
          //利用call(),让this的指针从指向window 转成指向input
        }, delay)
      }
    },
    register () {
      this.debounce(this.handleRegister(), 1000)
    }
  },
};

/** 登录用户名校验 */
function validateLoginName (rule, value, callback) {
  // 不为空
  if (value === "" || String(value).trim() === "") {
    callback(new Error(this.$t("common.loginHolder")));
    return;
  }
  callback();
}
/** 登录密码校验 */
function validateLoginPass (rule, value, callback) {
  // 不为空
  if (value === "" || String(value).trim() === "") {
    callback(new Error(this.$t("common.passHolder")));
    return;
  }
  callback();
}
</script>

<style lang="scss" scoped>
.system-login {
  height: 100vh;
  background-image: url("../../assets/image/login/login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  box-sizing: border-box;
  color: #333333;
  font-size: 14px;

  .system-login-card {
    padding: 10px 10px 30px 10px;
    width: 400px;
    position: absolute;
    right: 15%;
    top: 25%;

    .login-title {
      color: #333333;
      font-size: 24px;
      text-align: center;
      font-weight: 600;
      letter-spacing: 2px;
      margin: 23px 0 53px 0;
    }

    .login-content {
      .content-rember {
        position: relative;

        .remerber-pass {
          opacity: 0.43;
        }
      }

      .content-btn {
        margin-top: 20px;

        .btn-login {
          letter-spacing: 10px;
          width: 100%;
          font-size: 16px;
        }
      }

      .content-nocount {
        font-size: 14px;
        color: #a6a6a6;
        letter-spacing: 2px;
        margin-top: 20px;
        position: relative;

        .forget-pass {
          position: absolute;
          right: 0;
          color: #409eff;

          &:hover {
            cursor: pointer;
          }

          &:active {
            opacity: 0.75;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.system-login {
  .system-login-card {
    .login-input {
      .el-input--prefix {
        .el-input__prefix {
          .iconfont {
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
